<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:left>
                <Button className="--primary --small" v-if="$store.getters.hasPermission('store bookings')"
                        :onclick="generateReport" :disabled="is_printing">
                    {{ $t('reports.generate_report') }}
                </Button>
            </template>
            <template v-slot:right>
                <FormInputSelect v-model="selectedVehicle" identifier="vehicle" :options="vehicles"
                                 class="select-margin-fix filter-desktop"
                                 :placeholder="$t('bookings.license_plate')" :disabled="is_loading_vehicles"
                                 @updated="onFilterUpdated"
                                 track-by="id" :display-custom-label="(row) => `${row.attributes.license_plate} - ${row.relationships.brand.data.attributes.name} - ${row.attributes.model}`"/>

                <FormInputDateTime v-model="dateTimeFrom" identifier="pick_up_date"
                                   :placeholder="$t('bookings.pick_up_date_only')" @input="onFilterUpdated"
                                   :disabled="is_printing" :minute-interval="60" class="filter-desktop"/>

                <FormInputDateTime v-model="dateTimeTo" identifier="drop_off_date"
                                   :placeholder="$t('bookings.drop_off_date_only')" @input="onFilterUpdated"
                                   :disabled="is_printing" :minute-interval="60" class="filter-desktop"/>

                <Search class="search-desktop" :placeholder="$t('Search')" @search="search"/>

                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>

            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('bookings.search_bookings')"
                            @search="search"/>
                    <FormInputSelect v-model="selectedVehicle" identifier="vehicle" :options="vehicles"
                                     class="select-margin-fix mobile-margin-fix"
                                     :placeholder="$t('bookings.license_plate')" :disabled="is_loading_vehicles"
                                     @updated="onFilterUpdated"
                                     track-by="id" :display-custom-label="(row) => `${row.attributes.license_plate} - ${row.relationships.brand.data.attributes.name} - ${row.attributes.model}`"
                                     v-if="headbarExpanded === 'search'"/>

                    <FormInputDateTime v-model="dateTimeFrom" identifier="pick_up_date"
                                       :placeholder="$t('bookings.pick_up_date_only')"
                                       class="select-margin-fix mobile-margin-fix no-padding"
                                       :disabled="is_saving" :minute-interval="60" :min-date="todayDate"
                                       v-if="headbarExpanded === 'search'" @input="onFilterUpdated"/>

                    <FormInputDateTime v-model="dateTimeTo" identifier="drop_off_date"
                                       :placeholder="$t('bookings.drop_off_date_only')"
                                       class="select-margin-fix mobile-margin-fix no-padding"
                                       :disabled="is_saving" :minute-interval="60" :min-date="todayDate"
                                       v-if="headbarExpanded === 'search'" @input="onFilterUpdated"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="bookings"
                :isLoading.sync="is_loading_bookings"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: false,
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'role'">
                        <p style="text-transform: capitalize;">{{
                                props.row.relationships.roles.data[0].attributes.name
                            }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'attributes.from'">
                        <p style="text-transform: capitalize;">
                            {{ $moment(props.row.attributes.from).format('DD/MM/YYYY - HH:mm') }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'attributes.to'">
                        <p style="text-transform: capitalize;">
                            {{ $moment(props.row.attributes.to).format('DD/MM/YYYY - HH:mm') }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                        <router-link :to="{name: 'bookings-update', params: {id: props.row.id}}">
                            <Button
                                v-if="$store.getters.hasAnyPermission(['read bookings', 'update bookings'])"
                                className="--secondary --outline --mini --big-text">
                                <font-awesome-icon :icon="['fal', 'pencil']"/>
                            </Button>
                        </router-link>

                        <Button
                            v-if="$store.getters.hasPermission('destroy bookings')"
                            className="--secondary --outline --mini --big-text"
                            :onclick="()=>toggleDelete(props.row)">
                            <font-awesome-icon :icon="['fal', 'trash']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
            <vue-html2pdf v-if="is_printing" :float-layout="true"
                          :enable-download="true"
                          :filename="pdfFileName"
                          :pdf-quality="2"
                          :manual-pagination="true"
                          pdf-format="a4"
                          pdf-orientation="landscape"
                          pdf-content-width="100%"
                          @beforeDownload="beforeDownload($event)"
                          @hasDownloaded="hasDownloaded($event)"
                          ref="html2Pdf">
                <section slot="pdf-content" class="pdf-content">
                    <div class="pdf-content-container" v-for="index in totalPages" :key="index">
                        <div class="pdf-content-header">
                            <h1>{{ $t('reports.car_bookings_report') }} - Page {{index}}/{{totalPages}}</h1>
                            <h2 v-if="selectedVehicle">{{ $t('reports.license_plate') }}:
                                <span>{{ `${selectedVehicle.attributes.license_plate} - ${selectedVehicle.relationships.brand.data.attributes.name} - ${selectedVehicle.attributes.model}` }}</span></h2>
                            <h2 v-if="dateTimeFrom && dateTimeTo">{{ $t('reports.time_frame') }}:
                                <span>{{ $moment(dateTimeFrom).format('DD/MM/YYYY - HH:mm') }} to </span><span>{{ $moment(dateTimeTo).format('DD/MM/YYYY - HH:mm') }}</span></h2>
                        </div>

                        <vue-good-table
                            styleClass="vgt-table vgt-custom vgt-custom-pdf"
                            :columns="printingColumns"
                            :rows="bookings"
                            :isLoading.sync="is_loading_bookings"
                            :search-options="{
                   enabled: false,
             }"
                            :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: 10,
                    setCurrentPage: index,
                }"
                            :totalRows="totalRecords"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange">
                            <template slot="table-row" slot-scope="props">
                                <div v-if="props.column.field === 'role'">
                                    <p style="text-transform: capitalize;">
                                        {{ props.row.relationships.roles.data[0].attributes.name }}</p>
                                </div>
                                <div v-else-if="props.column.field === 'attributes.from'">
                                    <p style="text-transform: capitalize;">
                                        {{ $moment(props.row.attributes.from).format('DD/MM/YYYY - HH:mm') }}</p>
                                </div>
                                <div v-else-if="props.column.field === 'attributes.to'">
                                    <p style="text-transform: capitalize;">
                                        {{ $moment(props.row.attributes.to).format('DD/MM/YYYY - HH:mm') }}</p>
                                </div>
                                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                            </template>
                        </vue-good-table>
                        <div v-if="index !== totalPages" class="html2pdf__page-break"/>
                    </div>
                </section>
            </vue-html2pdf>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import FormInputSelect from "../../components/form/FormInputSelect";
import FormInputDateTime from "../../components/form/FormInputDateTime";
import VueHtml2pdf from 'vue-html2pdf'

export default {
    name: "reports-index-page",
    components: {ConfirmModal, Select, Button, Search, Headbar, FormInputSelect, FormInputDateTime, VueHtml2pdf},
    data: function () {
        const columns = [
            {
                label: this.$t('bookings.id'),
                field: 'id',
                sortable: false,
                width: '10%',
            },
            {
                label: this.$t('bookings.date_time_from'),
                field: 'attributes.from',
                sortable: false,
                width: '20%',

            },
            {
                label: this.$t('bookings.date_time_to'),
                field: 'attributes.to',
                sortable: false,
                width: '20%',
            },
            {
                label: this.$t('bookings.pick_up_location'),
                field: 'attributes.pick_up_location',
                sortable: false,
                width: '20%',
            },
            {
                label: this.$t('bookings.drop_off_location'),
                field: 'attributes.drop_off_location',
                sortable: false,
                width: '20%',
            },
            {
                label: this.$t('bookings.booking_by'),
                field: 'relationships.user.data.attributes.name',
                sortable: false,
                width: '20%',

            },
        ];

        const printingColumns = [
            {
                label: this.$t('bookings.id'),
                field: 'id',
                sortable: false,
                width: '10%',

            },
            {
                label: this.$t('bookings.date_time_from'),
                field: 'attributes.from',
                sortable: false,
                width: '15%',
            },
            {
                label: this.$t('bookings.date_time_to'),
                field: 'attributes.to',
                sortable: false,
                width: '15%',
            },
            {
                label: this.$t('bookings.pick_up_location'),
                field: 'attributes.pick_up_location',
                sortable: false,
                width: '15%',
            },
            {
                label: this.$t('bookings.drop_off_location'),
                field: 'attributes.drop_off_location',
                sortable: false,
                width: '15%',
            },
            {
                label: this.$t('bookings.booking_by'),
                field: 'relationships.user.data.attributes.name',
                sortable: false,
                width: '15%',
            },
        ];

        return {
            columns: columns,
            printingColumns: printingColumns,
            bookings: [],
            vehicles: [],
            dateTimeFrom: null,
            dateTimeTo: null,
            is_loading_bookings: false,
            is_loading_vehicles: false,
            selectedVehicle: null,
            selectedUser: null,
            totalRecords: null,
            totalPages: null,
            serverParams: {
              sorting: [],
              sort_order: 'desc',
              sort_by: 'from'
            },
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false,
            is_printing: false,
        }
    },
    methods: {
        async generateReport() {
            if (!this.selectedVehicle) {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$t('reports.choose_a_license_plate'),
                    type: 'error',
                });
                return;
            }
            if (!this.dateTimeFrom) {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$t('reports.choose_a_pickup_date'),
                    type: 'error',
                });
                return;
            }
            if (!this.dateTimeTo) {
                this.$notify({
                    title: this.$t('error'),
                    text: this.$t('reports.choose_a_dropoff_date'),
                    type: 'error',
                });
                return;
            }

            this.is_printing = true;

          this.$nextTick(async () => {
            await this.$refs.html2Pdf.generatePdf()
          });
        },

        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.retrieveBookings();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.retrieveBookings();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by = null;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.retrieveBookings();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.retrieveBookings();
        },

        async beforeDownload() {
            console.log(this.selectedVehicle)
            this.is_printing = true;
        },

        async hasDownloaded() {
            this.is_printing = false;
        },
        retrieveVehicles() {
            this.is_loading_vehicles = true;

            this.$axios.get('vehicles/list')
                .then(({data}) => {
                    this.vehicles = data.data;
                    this.is_loading_vehicles = false;
                })
                .catch(e => {
                    this.is_loading_vehicles = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('vehicles.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        onFilterUpdated() {
            const filters = [];

            if (this.selectedVehicle) {
                filters.push({
                    filter_by: 'vehicle_id',
                    filter_value: this.selectedVehicle.id
                })
            }

            if (this.dateTimeFrom) {
                filters.push({
                    filter_operator: '>',
                    filter_by: 'from',
                    filter_value: this.$moment(this.dateTimeFrom).format('YYYY-MM-DD HH:mm:ss')
                })
            }

            if (this.dateTimeTo) {
                filters.push({
                    filter_operator: '<',
                    filter_by: 'to',
                    filter_value: this.$moment(this.dateTimeTo).format('YYYY-MM-DD HH:mm:ss')
                })
            }

            if (filters.length)
                this.updateParams({filters: filters})
            else
                this.removeParam('filters');

            this.retrieveBookings();
        },
        retrieveBookings() {
            this.is_loading_bookings = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('bookings/list', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.bookings = data.data;

                    this.totalRecords = data.data.length;
                    this.totalPages = Math.ceil(this.totalRecords / 10);
                    this.is_loading_bookings = false;
                })
                .catch(e => {
                    this.is_loading_bookings = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('bookings.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.retrieveBookings();
        this.retrieveVehicles();
    },
    computed: {
        pdfFileName() {
            if (this.selectedVehicle) return this.selectedVehicle.attributes.license_plate.split(' ').join('_')
        }
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.reports')
            },
        }
    }
}
</script>
<style lang="scss" scoped>

.pdf-content {
    @apply px-4 h-full;

    .pdf-content-container {
        @apply flex flex-col h-full;

        ::v-deep .vgt-clearfix {
            display: none !important;
        }

        .pdf-content-header {
            @apply mb-8;

            h1 {
                @apply text-3xl font-bold mb-4;
            }

            h2 {
                @apply text-lg font-bold my-4;
            }

            span {
                @apply font-normal;
            }

            p {
                @apply mt-auto;
            }
        }
    }
}


.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .input-group {
        @apply mb-0;
    }

    ::v-deep .field-input {
        @apply w-full;
        height: 50px !important;
        min-height: 50px !important;


        @screen sm {
            margin-bottom: 0 !important;
            width: 150px !important;
            min-width: 150px !important;
        }
    }

    .select-margin-fix {
        margin-bottom: 0 !important;
        width: 150px !important;
        height: 50px !important;
        min-height: 50px !important;
        min-width: 150px !important;


        &.mobile-margin-fix {
            @apply mx-auto;
            width: 241px !important;
            padding-right: 4px;

            &.no-padding {
                @apply pr-0;
            }

            .date-time-picker {
                width: 241px !important;
            }
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mx-auto;

        @screen md {
            @apply ml-2;
        }

    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
